import "./App.css";
import React, { useState, useEffect } from "react";
import axios from "axios";

function App() {
  function Example() {
    const [days, setDays] = useState("0");

    useEffect(() => {
      async function fetchData() {
        const result = await axios(
          "https://be-days-since-last.onrender.com/date"
        );
        setDays(result.data.date);
      }

      fetchData();
    }, [days]);

    var lastDate = new Date(days);
    var currDate = new Date();
    var dateDiff = Math.abs(lastDate - currDate);
    var daysDiff = Math.floor(dateDiff / 86400000);

    return (
      <div>
        <p>It has been {daysDiff} days since a lm(f)ao in the Abode.</p>
        <button
          onClick={() => {
            axios.post("https://be-days-since-last.onrender.com/date");
            setDays(new Date().toISOString());
          }}
        >
          Reset
        </button>
      </div>
    );
  }

  return (
    <div className="App">
      <header className="App-header">
        <Example />
      </header>
    </div>
  );
}

export default App;
